const mandatoryDataUrl = "/mandatory/data"

export const useMandatoryTraining = () => {
  const modalOpen = useState<boolean | undefined>()

  function displayMandatoryTrainingModal(duration: number = 3000) {
    setTimeout(() => {
      modalOpen.value = true
    }, duration)
  }

  const data = useCachedFetch<Mandatory>(mandatoryDataUrl, {
    lazy: true,
    dedupe: "defer",
    method: "post",
  })

  const { data: cachedData } = useNuxtData<Mandatory>(mandatoryDataUrl)

  type TrainingType = "video" | "course" | "policy"

  const trainingKeys: Record<TrainingType, keyof Mandatory> = {
    video: "videos",
    course: "courses",
    policy: "policies",
  }

  function removeTrainingFromCachedMandatoryData(
    trainingType: TrainingType,
    trainingId: string,
  ) {
    if (!cachedData.value) {
      return
    }

    const trainingKey = trainingKeys[trainingType]

    const newTrainings = cachedData.value[trainingKey].filter(
      (training) => training.content.id != trainingId,
    )

    cachedData.value = {
      ...cachedData.value,
      [trainingKey]: newTrainings,
    }
  }

  return {
    modalOpen,
    displayMandatoryTrainingModal,
    ...data,
    removeTrainingFromCachedMandatoryData,
  }
}
